.slider-input {
    -webkit-appearance: none;
    width: 100%;
    height: 5px;
    background: linear-gradient(
            to right, /* Direzione del gradiente (verso destra nel nostro caso) */ black 0%, /* Colore nero all'inizio del track */ black calc((var(--value) - var(--min)) / (var(--max) - var(--min)) * 100%),
                /* Colore nero fino al punto rappresentato dal valore selezionato */ #ccc calc((var(--value) - var(--min)) / (var(--max) - var(--min)) * 100%),
                /* Colore grigio chiaro dal punto rappresentato dal valore selezionato fino alla fine */ #ccc 100% /* Colore grigio chiaro fino alla fine del track */
    );

    outline: none;
    opacity: 0.7;
    transition: opacity 0.15s ease-in-out;
    -webkit-transition: opacity 0.15s ease-in-out;
    border-radius: 0.5px; /* per arrotondare i bordi */
}
